import React from "react";

export default function FormLinks() {
    return (
        <>
            {/*<Link to={'https://forms.gle/jNfyTx6XXmtrSxZY9'} target="_blank" rel="noopener noreferrer"><Button color={'secondary'} variant="contained" sx={{mt: 1, mr: 1}}>Solo Registration</Button></Link>*/}
            {/*<Link to={'https://forms.gle/56UR4vZUuXENvd528'} target="_blank" rel="noopener noreferrer"><Button color={'secondary'} sx={{mr: 1, mt: 1}} variant="contained">Ensemble Registration</Button></Link>*/}
            {/*<Link to={'https://docs.google.com/forms/d/e/1FAIpQLSc8zkfwItONoIJwXIaupx6hHLvwbDt1tPStbMty57W02-ru1g/viewform?usp=sf_link'} target="_blank" rel="noopener noreferrer"><Button color={'secondary'} sx={{mt: 1}} variant="contained">Presentation Application</Button></Link>*/}
            {/*<Link to={'/Schedule'}><Button color={'secondary'} sx={{mt: 1}} variant="contained">Schedule of Events</Button></Link>*/}
        </>
    )
}