import Typography from "@mui/material/Typography";
import {Grid} from "@mui/material";
import React from "react";

export default function CompetitionInfo() {
    return (
        <div>
            <Grid container>
                <Grid item sm={0} md={2}>
                </Grid>
                <Grid item sm={12} md={8}>
                    <Typography sx={{fontWeight: 'bold'}} gutterBottom variant="h5" component="div">
                        North American Baroque Trumpet Competition
                    </Typography>

                    <Typography variant="body1">
                        All competitors in either the Solo Division or the Ensemble Division must play on a baroque trumpet (i.e., an original or replica of a valveless low-pitched trumpet with or without finger holes, played in a manner as close as possible to the way scholars understand how the trumpet was played in Europe during the 17th and 18th centuries). The adjudicators will be experienced practitioners who recognize the challenge of playing with and without vent holes and with equipment that has varying degrees of authenticity, as well as the added difficulty imposed by certain repertoire.
                    </Typography>

                    <br/>

                    <Typography variant="body1">
                        All competitors in either division must be members of the Historic Brass Society during the 2025 year. Competitors may join at <a href={"https://historicbrass.org"}>historicbrass.org</a>. All solo competitors must pay an application fee of $35. All ensembles must pay an application fee of $100.
                    </Typography>

                    <br/>

                    <Typography gutterBottom variant="body1" sx={{fontWeight: 'bold'}}>
                        All applications, solo and ensemble, are due by December 15, 2025.
                    </Typography>

                    <Typography sx={{marginTop: 3}} gutterBottom variant="h6" component="div">
                        Solo Division
                    </Typography>

                    <Typography variant="body1">
                    The solo competition is open to all trumpeters age 35 and under as of April
                    27, 2025. All competitors must play two pieces: (1) The applicant's choice
                    of one of 5 Sinfonias by <a href={"https://imslp.org/wiki/Category:Torelli,_Giuseppe"}  target="_blank" rel="noopener noreferrer">Giuseppe Torelli</a>, Sinfonia in D G1, G2, G4, G8, or
                    G9. (2) A second self-chosen work will also be performed. The total time of
                    both pieces must not exceed ten minutes.
                    </Typography>
                    <br/>

                    <Typography variant="body1">
                    In the live round, all works that have accompaniment must be performed with accompaniment. A keyboard player, Frank Nowell, will be available to accompany all competitors in the live round for a fee of $100. That fee will be paid directly to Mr. Nowell.
                    </Typography>
                    <br/>

                    <Typography variant="body1" sx={{pb: 2}}>
                    For the preliminary recorded round, it is encouraged that the competitor record with accompaniment, but if an appropriate instrument is not available, the competitor may make an unaccompanied recording. All performances and recordings will be at A = 415, and a PDF of the accompaniment should be uploaded with the competitor's application. Competitors must play the same pieces in the live round that they recorded in preliminary round.
                    </Typography>

                    {/*<Link to={'https://forms.gle/jNfyTx6XXmtrSxZY9'} target="_blank" rel="noopener noreferrer"><Button color={'secondary'} variant="contained">Solo Registration</Button></Link>*/}

                    <Typography sx={{marginTop: 3}} gutterBottom variant="h6" component="div">
                    Ensemble Division
                    </Typography>

                    <Typography variant="body1">
                        Any baroque trumpet ensemble may enter, regardless of affiliation with a
                        school. A single school may enter more than one ensemble. The minimum
                        group size is three players, not including auxiliary instruments. The average
                        age of all players in a group must not be greater than thirty years old at the
                        time of the competition (April 27, 2025). Individuals may not enter in more
                        than one ensemble. Groups must submit a preliminary round recording (1)
                        of at least five minutes, but not more than 10 minutes, of repertoire. Groups
                        may choose pieces that have auxiliary instruments (i.e., keyboard and/or
                        timpani).
                    </Typography>
                    <br/>

                    <Typography variant="body1">
                        In the live round, all works that have auxiliary instruments must be
                        performed with these instruments. An organist will be available to
                        collaborate with all groups in the live round for a fee of $100. A baroque
                        timpani player and appropriate instruments are available to all groups for a
                        fee of $50. The age of the keyboard player or timpanist will not factor into
                        the ensemble age eligibility.
                    </Typography>
                    <br/>

                    <Typography variant="body1" sx={{pb: 2}}>
                        For the preliminary recorded round, if repertoire is chosen with keyboard
                        and/or timpani, it is encouraged that the group record with these
                        instruments, but if appropriate instruments are not available, the group may
                        make recording without these instruments. All performances and
                        recordings will be at A = 415, and a PDF of the accompaniment should be
                        uploaded with the competitor&#39;s application. Groups must play the same
                        pieces in the live round that they recorded in preliminary round.
                    </Typography>

                    {/*<Link to={'https://forms.gle/56UR4vZUuXENvd528'} target="_blank" rel="noopener noreferrer"><Button color={'secondary'} variant="contained">Ensemble Registration</Button></Link>*/}
                </Grid>
                <Grid item sm={0} md={2}>
                </Grid>
            </Grid>
        </div>
    )
}